html {
    background-color: #8ccfff;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    line-height: 1.5;
    font-size: 1em;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

:root {
    --main-color: .blue;
  }


h1 {
    text-align: center;
    font-weight: 700;
}

h6 {
  margin-bottom: 0px;
  font-size: 60%;
}

form {
  display: contents;
}

select {
    background-color: rgb(215, 237, 255);
    border-radius: 5px;
    width: 150px;
    font-size: 1em;
}

select option {
  background-color: rgb(215, 237, 255);
}

.align3mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container2 h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

td a:visited {
  color: blue;
}

.App {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    table {
    border: 2px solid navy;
    /* width: 700px;
    height: 300px; */
    }
    
    th {
    border-bottom: 1px solid navy;
    }
    
    td {
    text-align: center;
    }

.blue {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }


.box1-2p {
    font-weight: 700;
    padding-right: 10px;
  }

.box3 {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
  }
  
  
.box3 h3 {
    padding-left: 15px;
  }


.card {
    padding: 20px 10px;
    /* border: 1px solid #000; */
    display:flex;
    flex-direction: column;
    align-items: center;
    /* width: 20%; */
    /* margin: 0 300px; */
}

.card2 {
  padding: 10px 10px;
  /* border: 1px solid #000; */
  display:flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
}

.card3 {
  padding: 20px 10px;
  /* border: 1px solid #000; */
  display:flex;
  flex-direction: row;
  align-items: center;
  /* width: 40%; */
}

.container {
    padding-bottom: 20px;
    /* background-color:#c5c7ff; */
    align-items: baseline;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.container2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.container3 {
  /* padding: 10px; */
  /* background: #e7e8ff; */
  background: #8ccfff;

}

.container4 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
  margin-top: -6px;
}

.container5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container5 a {
  margin-left: 10px;
  margin-right: 10px;
}

.container6 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.container6 h3 {
  font-size: 1.5em;
}

.container4 a:visited {
  color: blue;
}

.container6 a:visited {
  color: blue;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1rem;
  /* border-radius: 5px 5px 0 0; */
  border-radius: 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.content-table thead {
  background: white;
}

.content-table thead tr {
  background: .blue;
  background: var(--main-color);
  color:black;
  text-align: center;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 0.4rem 0.6rem;
  max-width: 240px;
  min-width: 100px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
  transition: all 0.1s ease-in;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: .blue;
  color: var(--main-color);
}

.content-table tbody tr:hover,
.content-table tbody tr.active-row:hover {
  background: #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid .blue;
  border-bottom: 2px solid var(--main-color);
}

:root {
  --main-color: .blue;
}

.emphasized {
  font-style: italic;
}

.green {
  color: #A1045A;
}


.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-twitter {  
  background: #55ACEE;  
  color: white;  
}

.fa-instagram {  
  background: #125688;  
  color: white;  
}

.footer {
  text-align: center;
  /* background-color: #e7e8ff; */
  background-color: #8ccfff;
  position:relative;
  margin-top: auto;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;

}

.flex-parent {
  display: flex;
  min-height: 0px;
}

.h2s {
  position:inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.img-left {
  width: 160px;
  padding-left: 10px;
}

.img-right {
  width: 160px;
 padding-right: 10px;

}

.jc-center {
    justify-content: center;
}

.k5 {
  padding-top: 40px;
  font-weight: 600;
  padding-bottom: 40px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.k6 {
  width: 300px;
  text-align: center;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 10px;
}

.link {
    color: #000;
}

.link:visited {
    color: #000;
}

.openSans {
  font-family: 'Open Sans', sans-serif;
}

.over65 {
  font-size: 120%;
}

.phone {
  min-width: 100px;
}

.row {
  display: flex;
  justify-content: space-evenly;
}
  
.slick-slide img {
    margin: auto;
  }

.sourceSansPro {
  font-family: 'Source Sans Pro', sans-serif;
}


.submit-btn {
  display:flex;
  background-color: rgb(144, 149, 214);
  border-radius: 5px;
  font-size: 1em;
}

.center {
  vertical-align: middle;
}

@media only screen and (max-width: 1350px) {
  .content-table {
    font-size: 0.85rem;
    border-radius: 0;
  }

  .content-table th,
  .content-table td {
    padding: 0.3rem 0.5rem;
    max-width: 240px;
    min-width: 90px;
  }
}

@media only screen and (max-width: 1140px) {
  .content-table {
    font-size: 0.8rem;
  }

  .container2 {
    display: block;
  }

  .content-table {
    overflow: auto;
  }

  table {
    border-radius: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .container {
    /* display: flex; */
    align-items: center;
    flex-direction: column;
    /* padding-bottom: 20px;
    justify-content: space-evenly; */
  }

  .container4 {
    align-items: center;
    flex-direction: column;
  }

  .container4 img {
    height: 170px;
  }

  .box1-2p {
    width: 100%;
  }

  .card2 {
    width: 92%;
  }

  h1 {
    /* padding-right: 10px; */
    font-size:130%;
  }

  .container3 img {
    height:170px;
  }

  .container6 {
    font-size: 80%;
  }

  .container6 img {
    height: 250px;
  }

  .img-left {
    width: 120px;
    padding-left: 10px;
    padding-right: 15px;
  }
  
  .img-right {
    width: 120px;
    padding-right: 10px;
    padding-left: 15px;
  }

  .right {
    flex-direction: row-reverse;
  }

  .right .box1-2p {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 700px) {

  h1 {
    /* padding-right: 10px; */
    font-size:100%;
  }

  .img-left {
    width: 100px;
    padding-left: 5px;
    padding-right: 10px;
  }
  
  .img-right {
    width: 100px;
    padding-right: 5px;
    padding-left: 10px;
  }

  .container4 p {
    font-size: 84%;
  }


.container6 {
  align-items: center;
  flex-direction: column;
}

.align3mobile {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}


.card3 {
  /* padding: 20px 10px; */
  /* border: 1px solid #000; */
  display:flex;
  flex-direction: column;
  align-items: center;
  /* width: 40%; */
}

.box3 {
  padding-left: 10px;
  padding-right:10px;
}

.footer h2 {
  font-size: 100%;
}

}
